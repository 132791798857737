
import { computed, defineComponent } from 'vue';
import i18n from '@/i18n';

export interface StepProps {
    stepNumber: number;
    i18nText: string;
    absoluteActiveStep: number;
    startAt: number;
    amountOfSteps: number;
    compact: boolean;
}

export default defineComponent<StepProps>({
    name: 'Step',
    props: {
        stepNumber: {
            type: Number,
            required: true
        },
        i18nText: {
            type: String,
            default: ''
        },
        absoluteActiveStep: {
            type: Number,
            required: true
        },
        startAt: {
            type: Number,
            required: true
        },
        amountOfSteps: {
            type: Number,
            required: true
        },
        hasCompletedSteps: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        }
    },

    setup(props, { emit }) {
        const isFirstStep = computed<boolean>(() => {
            return props.stepNumber === 0;
        });

        const isFinalStep = computed<boolean>(() => {
            return props.stepNumber === props.amountOfSteps - 1;
        });

        const isTodo = computed<boolean>(() => {
            return props.absoluteActiveStep < props.stepNumber;
        });

        const isActive = computed<boolean>(() => {
            return props.absoluteActiveStep === props.stepNumber;
        });

        const isDone = computed<boolean>(() => {
            return props.absoluteActiveStep > props.stepNumber;
        });

        const goBackToStep = () => {
            if (props.absoluteActiveStep > props.stepNumber) {
                emit('go-back-to-step', props.stepNumber);
            }
        };

        return {
            isFirstStep,
            isFinalStep,
            isActive,
            isTodo,
            isDone,
            goBackToStep,
            i18n
        };
    }
});
