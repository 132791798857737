import { DeepNullable } from '@/helpers/types';
import {
    BILLING_PLAN_TYPE,
    completeRegistrationVakantiewoningVerhuurder,
    completeRegistrationWithCompanyData,
    completeRegistrationWithoutCompanyData,
    USER_ACCOUNT_TYPE
} from 'dexxter-types';

export function createCompleteRegistrationWithoutCompanySubmitData(): DeepNullable<completeRegistrationWithoutCompanyData> {
    return {
        address: {
            busNumber: null,
            city: null,
            number: null,
            street: null,
            zipcode: null
        },
        birthDate: null,
        company: {
            name: null
        },
        professionalactivityId: null,
        role: null,
        schoolId: null,
        socialsecretariatId: null,
        subscriptionType: null,
        vatLiable: null,
        promocode: null,
        knowsUsFrom: null
    };
}
export function createCompleteRegistrationWithCompanySubmitData(data?: {
    subscriptionType?: BILLING_PLAN_TYPE;
    role?: USER_ACCOUNT_TYPE;
}): DeepNullable<completeRegistrationWithCompanyData> {
    return {
        address: {
            busNumber: null,
            city: null,
            number: null,
            street: null,
            zipcode: null
        },
        birthDate: null,
        company: {
            name: null,
            IBAN: null,
            number: null
        },
        professionalactivityId: null,
        role: data?.role ?? null,
        schoolId: null,
        socialsecretariatId: null,
        subscriptionType: data?.subscriptionType ?? null,
        vatLiable: null,
        companyStartDate: null,
        dexxterStartDate: null,
        promocode: null,
        knowsUsFrom: null
    };
}

export function createCompleteRegistrationVerhuurVakantiewoningenSubmitData(): DeepNullable<completeRegistrationVakantiewoningVerhuurder> {
    return {
        address: {
            busNumber: null,
            city: null,
            number: null,
            street: null,
            zipcode: null
        },
        birthDate: null,
        company: {
            name: null,
            IBAN: null,
            number: null
        },
        promocode: null,
        knowsUsFrom: null
    };
}
