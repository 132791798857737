<template>
    <div class="pt-4">
        <RadioButtonCards
            data-testid="tier-list"
            :options="registerableTiers"
            :active-card-key="activeCardKey"
            :amount-of-columns="1"
            @clickCard="selectTier"
        />
        <div class="button-wrapper mt-8">
            <router-link :to="{ name: 'completeUserRegistration' }">
                <v-btn outlined color="primary" class="font-weight-bold px-sm-8">
                    {{ $t('general.back') }}
                </v-btn>
            </router-link>
            <v-spacer />
            <SubmitButton data-action="go-to-form-step" @click="goToNextStep"> {{ $t('general.next') }}</SubmitButton>
        </div>
    </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton.vue';
import RadioButtonCards from '@/components/FormComponents/RadioButtonCards';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';

export default {
    name: 'PickTier',
    components: {
        SubmitButton,
        RadioButtonCards
    },
    mixins: [formRulesMixin, lookupCompanynumberMixin],
    data() {
        return {
            activeCardKey: 'secondary',
            registerableTiers: [
                {
                    key: 'student',
                    i18nPath: 'completeUserRegistration.selfEmployedStudent',
                    dataAction: 'select-student'
                },
                {
                    key: 'secondary',
                    i18nPath: 'completeUserRegistration.selfEmployedSecondaryActivity',
                    dataAction: 'select-secondary'
                },
                {
                    key: 'main',
                    i18nPath: 'completeUserRegistration.main',
                    dataAction: 'select-main'
                }
            ]
        };
    },
    methods: {
        selectTier(selectedUserType) {
            this.activeCardKey = selectedUserType;
        },
        goToNextStep() {
            this.$emit('nextStep', this.activeCardKey);
        }
    }
};
</script>
<style lang="scss" scoped>
.button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
</style>
