
import Vue, { PropOptions } from 'vue';
// --- Components ---
// --- State ---
// --- Error handling ---

export interface RadioCardOption {
    key: string;
    i18nPath: string;
    checkList?: string[];
    dataAction?: string;
}

export default Vue.extend({
    name: 'RadioButtonCards',
    props: {
        activeCardKey: {
            type: String,
            required: true
        } as PropOptions<string>,
        options: {
            type: Array,
            required: true
        } as PropOptions<RadioCardOption[]>,
        amountOfColumns: {
            type: Number,
            required: false,
            default: 2
        } as PropOptions<number | undefined>
    },
    computed: {
        cssVars(): { '--amount-of-columns': number | undefined } {
            return { '--amount-of-columns': this.amountOfColumns || 2 };
        }
    },
    methods: {
        emitSelectedCardKey(selectedKey: string): void {
            this.$emit('clickCard', selectedKey);
        }
    }
});
