<template>
    <v-row>
        <v-col cols="12" data-select-container="knows-us-from-top-level">
            <v-autocomplete
                v-model="topLevelSelect"
                item-value="id"
                item-text="label"
                :items="topItems"
                label="Van waar ken je ons *"
                outlined
                hide-details="auto"
                required
                dense
                persistent-hint
                :rules="requiredRules"
            />
        </v-col>

        <template v-if="topLevelSelect">
            <template v-if="canSelectItems">
                <v-col cols="12" data-select-container="knows-us-from-sub-level">
                    <v-autocomplete
                        v-model="subLevelSelect"
                        item-value="id"
                        item-text="label"
                        :items="subItems"
                        label="Van waar ken je ons *"
                        outlined
                        hide-details="auto"
                        dense
                        persistent-hint
                        :rules="requiredRules"
                    >
                        <template #no-data>
                            <div
                                class="v-list-item v-list-item--link theme--light"
                                role="option"
                                @click="subLevelSelect = 'Overig'"
                            >
                                <div class="v-list-item__content">
                                    <div class="v-list-item__title">Overig</div>
                                </div>
                            </div>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col v-if="subLevelSelect === 'Overig'" cols="12">
                    <text-field
                        v-model="result"
                        data-cy="knows-us-from-specific"
                        label="Specifieer hier... *"
                        :rules="requiredRules"
                    />
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12">
                    <text-field
                        v-model="result"
                        data-cy="knows-us-from-specific"
                        label="Specifieer hier..."
                        :rules="requiredRules"
                    />
                </v-col>
            </template>
        </template>
    </v-row>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    name: 'KnowsUsFromSelect',
    mixins: [formRulesMixin],
    data() {
        return {
            topLevelSelect: null,
            subLevelSelect: null,
            result: null,
            // Provide fallback value for structured if it cannot fetch it from remote resource
            structured: {
                socialSecretariat: {
                    type: 'selectAndType',
                    translations: {
                        nl: 'Acerta, Group S, Liantis, Unizo, Xerius'
                    },
                    data: ['Acerta', 'Group S', 'Liantis', 'Unizo', 'Xerius']
                },
                boekhouder: {
                    type: 'selectAndType',
                    translations: {
                        nl: 'Boekhoudkantoor'
                    },
                    data: [
                        'A.coppin & Partners',
                        'A.F.A. Finance',
                        'AB2W',
                        'ACB Tax Consult',
                        'Accnoord',
                        'Accountantskantoor D & V',
                        'Accountantskantoor De Baets',
                        'Accounting Team',
                        'ACS Accountants',
                        'ACS Lombardia',
                        'Actabo',
                        'ADE Consultants',
                        'Admin-helpdesk',
                        'Administri Consulting',
                        'Andante',
                        'Andere',
                        'Annick Jeanty',
                        'At account',
                        'AXXI',
                        'B&A Advies BV',
                        "Balan'z",
                        'Beleac NV',
                        'BDS Accountancy',
                        'BIFAS',
                        'BoekhouderOnline',
                        'Boekhouding Els Leyssens',
                        'Boekhoudkantoor Jacobs Maurice',
                        'Boekhoudkantoor Peerens',
                        'Boekhoudkantoor-SVL',
                        'Bofidi',
                        'Bookkeepers',
                        'Carbofisc',
                        'Carmans G. Accountants',
                        'CDS Tax advisors',
                        'Certifisc',
                        'Confidacc',
                        'Connectum',
                        'Coopman & Partners Boekhouding en Fiscaliteit',
                        'Crauwels Van Dam Accountants',
                        'Creafig',
                        'Creative Finance',
                        'Crowe Spark',
                        'D-Services',
                        "D'Hollander & Partners - DHP Tax",
                        'De Bondt & Van Buggenhout',
                        'De Deyne Gino',
                        'Dema Consultancy',
                        'Derofisc',
                        'Dfisc',
                        'Digicount',
                        'DKC Services',
                        'Dries Menten',
                        'EC BOEKHOUDING & FISCALITEIT BV',
                        'Fact-is',
                        'Factum Fiscalis',
                        'FIDEL Accountants',
                        'Fidius',
                        'Fiduciaire Costermans',
                        'Fiduciaire Oost-Boek',
                        'FinaSense',
                        'Fisc-it',
                        'Fiscal+',
                        'Fiskaplus',
                        'Fiskcouncil+',
                        'Four Eyes',
                        'Goossens accountancy',
                        'Graef advies',
                        'Gyselinck & Co BV',
                        'HB Accountants',
                        'Het ondernemershuis',
                        'Impacct accountants',
                        'Ingrid Ledez Admin Consultancy',
                        'JACCK',
                        'Kantoor Dewulf',
                        'Kantoor Humblet',
                        'Kantoor Huyghe',
                        'Kantoor Van Steendam',
                        'Kantoor Verkest',
                        'Kay Croenen',
                        'KDCA',
                        'kmo Business Consult',
                        'Koenraad Tranchet - EDUFISC',
                        'Lambregts & Robben',
                        'Liberoo',
                        'Liberoo Merksem',
                        'LMI',
                        'Mambo Consulting',
                        'Masure Accountantskantoor',
                        'Melange Advice',
                        'Melina Zovko - MTZ Consulting',
                        'Meraki',
                        'Mijn boekhouder',
                        'Mitch-Admin',
                        'Moore',
                        'MV',
                        'Ondrnmr.',
                        'Onsia Fiduciaire',
                        'Ontward accounting',
                        'Ordo',
                        'Peeters Katarina BV',
                        'PIA-groep',
                        'Plusminder',
                        'R/S Boekhoudkantoor',
                        'Rekenen en Tellen',
                        '$B Consultancy',
                        'SBB Accountants & Adviseurs',
                        'SBO Consulting',
                        'Sels & Verhoeven Accountancy - SVA-accountants',
                        'Sinacco',
                        'Tellent',
                        'Thomas Van Damme',
                        'Triple Accountancy',
                        'TS Tax Consultants',
                        'Van Den Neste & Partners',
                        'Van Havermaet',
                        'VCSA Accountants',
                        'VDL Tax',
                        'VDV Accountants',
                        'VVH&P',
                        'Warfid'
                    ]
                },
                online: {
                    type: 'selectAndType',
                    translations: {
                        nl: 'Online'
                    },
                    data: ['Google', 'Facebook', 'Instagram', 'Linkedin', 'YouTube', 'TikTok']
                },
                studentOrganisations: {
                    type: 'selectAndType',
                    translations: {
                        nl: 'Organisatie student-zelfstandigen'
                    },
                    data: [
                        'Baas',
                        'Gentrepreneur',
                        'Ice Cube',
                        'Kick/Le(j)on',
                        'SINC',
                        'StudentStartup Hasselt',
                        'Studentstartups.eu',
                        'Turbo'
                    ]
                },
                vriendenEnFamilie: {
                    type: 'onlyType',
                    translations: {
                        nl: 'Vriend, kennis, familie, …'
                    }
                },
                other: {
                    type: 'selectAndType',
                    translations: {
                        nl: 'Andere (offline, partnerwebsite, …)'
                    },
                    data: ['Boekhouderofferte.be', 'KBC', 'Konnektit', 'Resirent']
                }
            }
        };
    },
    computed: {
        topItems() {
            return Object.keys(this.structured).map((_x) => ({
                id: _x,
                label: this.structured[_x].translations.nl
            }));
        },
        canSelectItems() {
            return (
                this.structured[this.topLevelSelect].type === 'selectAndType' ||
                this.structured[this.topLevelSelect].type === 'onlySelect'
            );
        },
        canAddOverig() {
            return this.structured[this.topLevelSelect].type === 'selectAndType';
        },
        subItems() {
            if (this.canAddOverig) {
                return this.structured[this.topLevelSelect].data.concat('Overig');
            }
            return this.structured[this.topLevelSelect].data;
        }
    },
    watch: {
        subLevelSelect(value) {
            if (value !== 'Overig') {
                this.result = value;
            } else {
                this.result = null;
            }
        },
        topLevelSelect() {
            this.result = null;
            this.subLevelSelect = null;
        },
        result(value) {
            if (value) {
                this.$emit('change', `${this.topLevelSelect}.${value}`);
            } else {
                this.$emit('change', null);
            }
        }
    }
    // async created() {
    //     try {
    //         // const websiteResult = await Axios.get(
    //         //     reformatURLForGoogleStorageCORS(
    //         //         'https://storage.googleapis.com/dexxter-production-application/van-waar-kent-u-ons.json'
    //         //     )
    //         // ).then((resp) => resp.data);
    //         // const validator = new Validator.Validator();
    //         // const validationResult = validator.validate(websiteResult, schema);
    //         // if (validationResult.valid) {
    //         //     this.structured = websiteResult;
    //         // } else {
    //         //     throw new Error('Schema not valid');
    //         // }
    //     } catch (e) {
    //         reportError(e);
    //     }
    // }
};
</script>

<style lang="scss" scoped></style>
