import axios from 'axios';
import { IProfessionalactivityGetAllViewModel } from 'dexxter-types';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/professionalactivity');

export function sendGetAllProfessionalactivitiesRequest(
    language: string
): Promise<IProfessionalactivityGetAllViewModel[]> {
    return axios
        .get(BASE_URL, {
            params: {
                language
            }
        })
        .then((response) => response.data);
}
