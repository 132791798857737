
import { computed, defineComponent, PropOptions } from 'vue';
import Step, { StepProps } from '@/components/stepper/Step.vue';

interface StepperHeaderProps {
    activeStep: number;
    startAt: number;
    hasCompletedSteps?: boolean;
    // When no steps are provided, amountOfSteps is required
    steps?: StepProps[];
    amountOfSteps?: number;
}

export default defineComponent<StepperHeaderProps>({
    name: 'StepperHeader',
    components: { Step },
    props: {
        activeStep: {
            type: Number,
            required: true
        },
        startAt: {
            type: Number,
            default: 0
        },
        hasCompletedSteps: {
            type: Boolean,
            default: false
        },
        steps: {
            type: Array
        },
        amountOfSteps: {
            type: Number
        }
    },
    setup(props, { emit }) {
        const isCompact = computed<boolean>(() => {
            if (props.steps) {
                return props.steps.length > 4;
            } else {
                return true;
            }
        });

        const computedAmountOfSteps = computed<number>(() => {
            if (props.amountOfSteps) {
                return props.amountOfSteps;
            } else if (props.steps) {
                return props.steps.length;
            } else {
                return 0;
            }
        });

        const computedSteps = computed<StepProps[]>(() => {
            if (props.steps) {
                return props.steps;
            } else if (props.amountOfSteps) {
                return new Array(props.amountOfSteps).fill('');
            } else {
                return [];
            }
        });

        // Returns the active step starting from zero so standard array indexes can be used to determine the state of a step
        const absoluteActiveStep = computed<number>(() => {
            return props.activeStep - props.startAt;
        });

        const goBackToStep = (step: number): void => {
            if (absoluteActiveStep.value > step) {
                emit('go-back-to-step', step + props.startAt);
            }
        };

        return {
            absoluteActiveStep,
            isCompact,
            computedAmountOfSteps,
            computedSteps,
            goBackToStep
        };
    }
});
