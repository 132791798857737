var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{class:[
        'step',
        {
            todo: _vm.isTodo,
            active: _vm.isActive,
            done: _vm.isDone,
            final: _vm.isFinalStep
        }
    ]},[_c('div',{class:['circle', { final: _vm.isFinalStep }],on:{"click":function($event){$event.stopPropagation();return _vm.goBackToStep.apply(null, arguments)}}},[(_vm.isFinalStep)?_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-flag")]):(_vm.isActive || _vm.isTodo)?_c('div',[_vm._v(" "+_vm._s(_vm.stepNumber + _vm.startAt)+" ")]):_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-check")])],1),(!_vm.compact)?_c('p',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.i18n.t(_vm.i18nText)))]):_vm._e(),_c('div',{staticClass:"horizontal-rule"},[_vm._v(" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }